.links {
  padding-bottom: 3px;
  border-bottom: 1px solid #1890ff;
}
.delivery-partner-img {
  max-width: 15px;
}
.address-line {
  white-space: break-spaces;
}
.ant-btn-primary.porter-btn {
  border-color: transparent;
  background: linear-gradient(to right, #2961FE, #5D0DE1);
}
.ant-btn-primary.porter-btn:hover {
  border-color: transparent;
  background: linear-gradient(to right, #2961FE, #5D0DE1);
}
.ant-btn-primary.dunzo-btn {
  border-color: transparent;
  background: linear-gradient(90deg, rgb(133 237 168) 0%, rgb(77 176 243) 100%);
}
.ant-btn-primary.dunzo-btn:hover {
  border-color: transparent;
  background: linear-gradient(90deg, rgb(133 237 168) 0%, rgb(77 176 243) 100%);
}
.ant-btn-primary.correct-task-btn {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.ant-btn-primary.correct-task-btn:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
.ant-table-tbody > tr > td a.tracking-link {
  white-space: break-spaces;
}
/* Order receipt styles */
.print_wrapper {
    page-break-after: always;
    font-size: 0.875rem;
    max-width: 2.8in;
    margin: 1rem;
    color: #000000;
}
.print_wrapper p {
  margin-bottom: 0px;
}
.print_wrapper .divider {
  margin: 5px 0;
  border-top: 1px dashed #2C2C2C;
}
.print_wrapper:last-child {
  page-break-after: avoid;
}
.print_wrapper .order_details {
  display: flex;
  justify-content: space-between;
}
.print_wrapper .print_header p {
  text-align: center;
  margin: 3px 0;
}
.print_wrapper .print_header .outlet_name {
  font-weight: 600;
}
.print_wrapper .print_footer .header {
  font-size: 1rem;
}
.print_wrapper .print_footer p, .print_wrapper .print_footer .disclaimer {
  text-align: center;
  margin-bottom: 0px;
}
.print_wrapper .print_footer .end, .print_wrapper .print_footer .disclaimer {
  font-size: 0.75rem;
}
.print_wrapper .summary {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}
.print_wrapper .summary.bold {
  font-weight: 500;
}
.print_wrapper .summary .item_details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
}
.print_wrapper .summary .item_details .addons, .print_wrapper .summary .item_details .variations {
  font-size: 0.75rem;
}
.ant-input-group-wrapper.search_input {
  width: 25%;
}
@media screen {
  :host {
    display: none;
  }
}
@media print {
  @page { size: portrait; }
}
@media (max-width: 420px) {
  .ant-input-group-wrapper.search_input {
    width: 100%;
  }
}
